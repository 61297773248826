import { lazy } from "react";
import { IRoutes } from "../interface/routes";

const Dashboard = lazy(() => import("../views/dashboard/Dashboard.page"));
const User = lazy(() => import("../views/usermanagement/user/User.page"));
const Group = lazy(() => import("../views/usermanagement/group/Group.page"));
const Organization = lazy(
  () => import("../views/bookmarks/organization/Organization.page")
);
const TeamCollection = lazy(
  () => import("../views/bookmarks/teamcollection/TeamCollection.page")
);
const Tags=lazy(()=>import("../views/tags/Tags.page"))
const Subscriptions = lazy(
  () => import("../views/subscription/Subscription.page")
);
const ClickedCount=lazy(()=>import("../views/bookmarks/bookmarkcount/BookmarkCount"))
const Settings = lazy(() => import("../views/settings/Setting.page"));
const Help = lazy(() => import("../views/helpandsupport/Help&Support.page"));
const SignIn = lazy(() => import("../views/auth/SignIn.page"));
const LoginSuccess = lazy(() => import("../views/auth/SignInSuccess.page"));
const NotFound = lazy(() => import("../views/auth/NotFound.page"));
const RequestUserAddition=lazy(()=>import("../views/auth/RequestAdminAccess.page"))
const Error = lazy(() => import("../views/error/Error.page"));
const InternalServerError =lazy(()=>import("../views/auth/InternalServerError"));
const Unauthorized=lazy(()=>import("../views/auth/Unauthorized.page"))
const Expired=lazy(()=>import("../views/auth/Expired.page"))


const coreRoutes: IRoutes[] = [
  {
    path: "/",
    title: "Dashboard",
    component: Dashboard,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/userManagement/users",
    title: "User",
    component: User,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/userManagement/groups",
    title: "Group",
    component: Group,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/bookmarks/organization",
    title: "Organization",
    component: Organization,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/bookmarks/teams-collection",
    title: "TeamsCollection",
    component: TeamCollection,
    isProtected: true,
    withNavigation: true,
  },
  {
    path:"/bookmarks/clickedcount",
    title:"Clicked Count",
    component:ClickedCount,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/subscription",
    title: "Subscription",
    component: Subscriptions,
    isProtected:true,
    withNavigation: true,
  },
  {
    path: "/settings",
    title: "Settings",
    component: Settings,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/help",
    title: "Help&Support",
    component: Help,
    isProtected: true,
    withNavigation: true,
  },
  {
    path:"/tags",
    title:"Tags",
    component:Tags,
    isProtected:true,
    withNavigation:true
  },

  //login
  {
    path: "/login",
    title: "Sign In",
    component: SignIn,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/loginSuccess",
    title: "Login Success",
    component: LoginSuccess,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/notfound",
    title: "Not Found",
    component: NotFound,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/internal-server-error",
    title: "Not Found",
    component: InternalServerError,
    isProtected: false,
    withNavigation: false,
  },
  {
    path:"/unauthorized",
    title:"Unauthorized",
    component:Unauthorized,
    isProtected:false,
    withNavigation:false
  },
  {
    path:"/account/create",
    title:"RequestUserAddition",
    component:RequestUserAddition,
    isProtected:false,
    withNavigation:false
  },
  {
    path:"/subscription-expired",
    title:"Expired",
    component:Expired,
    isProtected:false,
    withNavigation:false
  },


  //Error
  {
    path: "*",
    title: "Error",
    component: Error,
    isProtected: false,
    withNavigation: false,
  },
];

const routes = [...coreRoutes];
export default routes;
